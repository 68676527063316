import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { CardBag } from "../../Interactions";
import PreInteraction from "../../PreInteraction";
import ResultGame from "../../Response/ResultGame";
import styled from "styled-components";
import Avatar from "../../../Avatar/Avatar";
import { respondTo } from "../../../../theme/mixin";
import Slider from "react-slick";
import { Button, Modal } from "../../../Ui";
import * as actionCreators from "../../../../store/actions";
import { Link } from "react-router-dom";
import { CheckCircle } from "@styled-icons/boxicons-solid";
import ContestPrize from "../../../../views/ContestPrize/ContestPrize";
import useSpring from "../../../../hooks/useSpring";
import Switch from "react-switch";
const CardHandlerWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 350px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 30px;
      }
    }

    .ramaining-attempts {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 30px;

      ${respondTo.md`
        margin-top: 0;
        position: absolute;
        bottom: 80px;
      `}

      .ramaining-info {
        color: #6cb967;
        margin-left: 10px;
      }
    }
  }

  .play-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 10px;

    ${respondTo.md`
      padding: 0;
    `}

    .play-box-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      z-index: 1;
      position: relative;

      h4 {
        margin-left: 10px;
        font-size: 21px;
        font-weight: 700;
      }
    }

    .background-pasqua {
      width: 100%;
      height: 100%;
    }
    .img-gioco {
      position: initial;
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      ${respondTo.md`
        position: absolute;
      `}
    }
    .play-box-rules {
      width: 100%;
      background-color: #6cb967;
      border-radius: 15px;
      padding: 15px;

      ${respondTo.md`
        width: calc(45% - 15px);
      `}

      h4 {
        color: #fff;
      }

      p {
        line-height: 1.5;
      }

      .play-box-rules-image {
        text-align: center;
      }
      .flex-prizes-list {
        display: flex;
        justify-content: space-around;
        gap: 10px;
      }
      .prizes-list {
        margin: 10px 0;
        display: flex;
        flex-direction: column;

        margin: 30px 0;
        width: 100%;
        align-items: center;
        img {
          width: 160px;
        }

        ${respondTo.sm`
        
      `}
      }
      .prizes-col {
        text-align: center;
      }

      .prize-icon {
        background-color: #3f943a;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: relative;
        top: -25px;
        margin: 0 auto;
        transition: all 0.2s;

        &:hover {
          background-color: #bfffbb;
          color: #3f943a;
          transition: all 0.2s;
        }

        .icon-not-selected {
          width: 20px;
          height: 20px;
          border: 2px solid #fff;
          border-radius: 50%;
        }

        &.disabled {
          opacity: 0.7;
          pointer-events: none;
        }
      }

      .prize-info {
        position: relative;
        margin-top: 15px;

        span {
          display: block;
          text-transform: uppercase;
          color: #96f290;
          font-weight: 300;
        }

        strong {
          display: inline-block;
          text-transform: uppercase;
          cursor: pointer;
          margin-top: 5px;

          &:hover {
            color: #96f290;
          }
        }

        small {
          font-weight: 700;
          font-size: 14px;
          color: #fff;
        }
      }

      &.selected {
        .prize-image {
          opacity: 0.5;
        }

        .prize-icon {
          color: #3f943a;
          background-color: #96f290;
        }
        .prize-info {
          strong {
            color: #96f290;
          }
        }
      }
    }
  }

  .play-box-game {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    //padding: 15px 30px 15px 15px;
    box-shadow: 0px 5px 15px #00000026;
    overflow: hidden;
    margin-top: 50px;
    .play-box-header {
      position: absolute;
      top: 15px;
      left: 15px;
    }
    ${respondTo.md`
        min-height: 550px;
      `}

    /*  &:before {
      content: "";
      display: block;
      width: 10px;
      height: calc(100% - 20px);
      position: absolute;
      right: 0;
      top: 10px;
      background-color: #fff;
      z-index: 1;
    } */

    ${respondTo.md`
        width: calc(55% - 15px);
        margin-top: 0;
      `}

    h4 {
      color: #6cb967;
    }

    .back-desktop {
      position: relative;

      &.hasWon {
        opacity: 0.3;
      }

      ${respondTo.md`
          position: absolute;
          top: 60px;
          left: 15px;
          width: calc(100% - 50px);
        `}
    }

    .bag-prizes {
      position: absolute;
      left: 75px;
      z-index: 10;
      display: flex;
      margin-top: 35px;

      ${respondTo.md`
          left: 155px;
        `}

      .bag-prize {
        position: relative;
        margin: 0 3px;

        .bag-remove {
          position: absolute;
          top: 0;
          right: 0;
          width: 27px;
          height: 27px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #b73e27;
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
        }

        .bag-image {
          overflow: hidden;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 0px 5px 15px #00000029;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: auto;
            height: 45%;
          }
        }
      }
    }

    .play-box-background {
      position: absolute;
      top: 20px;
      left: 0;
      width: calc(100% - 20px);
      z-index: 0;
    }
  }

  .privacy-consens {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    z-index: 1;
    .privacy-content {
      text-align: center;
    }
    h5 {
      color: #6cb967;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 30px;
    }

    span {
      font-size: 12px;
    }

    .privacy-checkbox {
      background-color: #fff;
      box-shadow: 0px 5px 15px #00000026;
      border-radius: 15px;
      width: 270px;
      height: 44px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;

      a {
        color: #185e14;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }
`;

export const Asterisk = styled.small`
  font-size: 10px;
  color: #adb1bc;
  display: block;
  margin-top: 20px;
  line-height: 1.5;
  padding: 0 15px;
  z-index: 1;

  ${respondTo.md`
    padding: 0;
  `}

  a {
    color: #eb5a3f;
    text-decoration: none;
  }
`;

const BackHandlerSpring = (props) => {
  const dispatch = useDispatch();

  const {
    g: {
      id,
      preinteraction,
      log: { steps, won },
      gamePrivacyAccepted,
    },
    played,
    action,
  } = props;
  const carnevale = useSpring({ id });
  const options = {
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const interactionData = JSON.parse(props.g.interaction.data);
  const prizesListObj = interactionData && interactionData.i18l.it.prizes;

  var prizesList = Object.keys(prizesListObj ? prizesListObj : {}).map(
    (key, index) => {
      return { id: key, ...prizesListObj[key] };
    }
  );

  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }

  const [showPrizeClicked, setShowPrizeClicked] = useState(null);
  const [prizes, setPrizes] = useState([]);
  const [prizesError, setPrizesError] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction); // true or false in base al fatto che ci sia la prop preinteraction
  const [userHasWon, setUserHasWon] = useState(null);
  const [prizeFromIw, setPrizeFromIw] = useState();

  const remaining_attempts = useSelector(
    (state) => state.promo.game[0].remaining_attempts
  );
  const currentUser = useSelector((state) => state.user.user.userinfo);

  let content = null;

  const onShowResult = () => setShowResult(true);

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };

  const retryHandler = () => {
    dispatch(actionCreators.getGame(id, "vincisempredipiu", ""));
    //dispatch(actionCreators.getGame(id, "vincidipiu", ""));
  };

  const managePrizesHandler = (prizeToAdd) => {
    let newPrizes = [...prizes];

    const alreadyInPrizes = newPrizes.find(
      (prize) => prize.id === prizeToAdd.id
    );

    if ((!played || showResult) && gamePrivacyAccepted) {
      if (prizes.length < 2 && !alreadyInPrizes) {
        newPrizes.push(prizeToAdd);
      } else {
        setPrizesError(true);
      }
    }

    setPrizes(newPrizes);
  };

  const removePrizeHandler = (index) => {
    let newPrizes = [...prizes];
    newPrizes.splice(index, 1);
    setPrizes(newPrizes);
  };

  const acceptGameRulesHandler = () => {
    dispatch(actionCreators.acceptGameRules(id));
  };

  useEffect(() => {
    if (action.data[0]) {
      setUserHasWon(action.data[0].won);
      setPrizeFromIw(action.data[0].prize);
    }
  }, [action]);

  content = (
    <>
      <CardHandlerWrapper>
        <div className="slide">
          <img
            src={
              process.env.PUBLIC_URL + "/images/vinci-di-piu-slide-natale.jpg"
            }
            alt=""
          />

          <div className="content">
            <div>
              <h3>Accendi la magia del Natale!</h3>
              <p>
                Festeggiamo insieme l'arrivo delle feste!{" "}
                <strong>Dal 16 al 20 dicembre</strong>, partecipa al nostro
                nuovo concorso di Natale: prova ad accendere le luci dell'albero
                e scopri subito se hai vinto.
                <br />
                Ogni giorno in palio{" "}
                <strong>due smartphone Samsung Galaxy 5G 256 Gb</strong>. <br />
                In più partecipi all'estrazione finale di due super premi:{" "}
                <strong>due Samsung TV QLED 75" THE FRAME</strong>. Buona
                fortuna!
              </p>
            </div>

            {currentUser && (
              <div className="ramaining-attempts">
                <Avatar shadow />
                <div className="ramaining-info">
                  {currentUser.firstname}, hai
                  <br />
                  <strong>
                    {remaining_attempts === null ? 0 : remaining_attempts}{" "}
                    possibilità di gioco
                  </strong>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="play-box-wrapper">
          <div className="play-box-rules">
            <div className="play-box-header">
              <img
                src={process.env.PUBLIC_URL + "/icons/play-icon.svg"}
                alt=""
              />
              <h4>Come partecipare al concorso</h4>
            </div>
            <p>
              Accetta il regolamento,{" "}
              <strong>clicca sull’albero di Natale</strong> e scopri subito se
              hai vinto.
              <br /> Per visionare le caratteristiche dei premi in palio, clicca
              sulle immagini qui sotto.
            </p>
            <div className="flex-prizes-list">
              <div className="prizes-list">
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    marginBottom: "10px",
                  }}
                >
                  INSTANT WIN
                </p>
                <Link to="/prize/1">
                  <img
                    src={process.env.PUBLIC_URL + "/images/natalePromo.png"}
                    alt=""
                  />
                </Link>
                <div style={{ textAlign: "center" }}>
                  <p style={{ color: "#96F290" }}>Samsung</p>
                  <p>
                    <strong>
                      GALAXY
                      <br /> 5G 256 GB
                    </strong>
                  </p>
                </div>
              </div>
              <div className="prizes-list">
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    marginBottom: "10px",
                  }}
                >
                  ESTRAZIONE FINALE
                </p>
                <Link to="/prize/2">
                  <img
                    src={process.env.PUBLIC_URL + "/images/natalePromo2.png"}
                    alt=""
                  />
                </Link>
                <div style={{ textAlign: "center" }}>
                  <p style={{ color: "#96F290" }}>Samsung</p>
                  <p>
                    <strong>
                      TV QLED 75”
                      <br />
                      THE FRAME
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <p>
              <strong>Se non hai vinto, non preoccuparti. </strong>
              <br />
              Puoi sempre ritentare domani. <br /> Ricorda che da + anni sei
              cliente Linkem, + tentativi hai a disposizione.
            </p>
          </div>
          <div className="play-box-game">
            {gamePrivacyAccepted && (
              <div className="play-box-header">
                <img
                  src={process.env.PUBLIC_URL + "/icons/play-icon.svg"}
                  alt=""
                />
                <h4>Gioca</h4>
              </div>
            )}
            {/*  {!showResult && true && ( */}
            {!showResult && !played && gamePrivacyAccepted && (
              <img
                className="background-pasqua"
                src={process.env.PUBLIC_URL + "/images/nataleClick.png"}
                alt=""
              />
            )}
            {!showResult && !played && !gamePrivacyAccepted && (
              <img
                className="img-gioco"
                src={
                  process.env.PUBLIC_URL + "/images/accepteRegulationNatale.png"
                }
                alt=""
              />
            )}

            {showResult || played ? (
              <ResultGame
                type="back-to-school"
                retry={() => retryHandler()}
                remaining_attempts={remaining_attempts}
                prizeFromIw={prizeFromIw}
                {...props}
              />
            ) : !showInteraction && steps.preinteraction.data === null ? (
              <PreInteraction
                preinteraction={preinteraction}
                onShowInteractionHandler={onShowInteractionHandler}
                {...props}
              />
            ) : (
              gamePrivacyAccepted && (
                <CardBag
                  remaining_attempts={remaining_attempts}
                  canPlay={true}
                  type="back-to-school"
                  typeInteraction="interaction"
                  onShowResult={onShowResult}
                  {...props}
                />
              )
            )}
            {/* <img
              className={`back-desktop ${userHasWon || won ? "hasWon" : null}`}
              src={
                process.env.PUBLIC_URL +
                `/images/${
                  userHasWon || won
                    ? "contest-carnevale-play-lose-background.svg"
                    : "contest-carnevale-play-background.png"
                }`
              }
              alt=""
            /> */}

            {!gamePrivacyAccepted && (
              <div className="privacy-consens">
                <div className="privacy-content">
                  <h5>
                    Accetta il regolamento per
                    <br />
                    partecipare al concorso
                    <br />
                    di Natale.
                  </h5>
                  <div className="privacy-checkbox">
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        "/pdf/2024 TISCALI - ACCENDI LA MAGIA DEL NATALE.pdf"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Regolamento
                    </a>
                    <Switch
                      onChange={acceptGameRulesHandler}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                    />
                    {/* <Button
                      small
                      gradient={{ left: "#6CB967", right: "#96F290" }}
                      hoverGradient={{ left: "#96F290", right: "#96F290" }}
                      onClick={acceptGameRulesHandler}
                    >
                      Accetto
                    </Button> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </CardHandlerWrapper>

      <Asterisk>
        Accendi la magia del Natale: concorso a premi valido complessivamente
        dal 16/12/2024 al 20/12/2024. L’estrazione finale, la verbalizzazione
        dei vincitori Instant Win e l’eventuale estrazione a recupero avverranno
        entro il 10/02/2025. Montepremi complessivo Euro 8.604,12 iva non
        esposta. Regolamento disponibile{" "}
        <Link
          to={{
            pathname:
              process.env.PUBLIC_URL +
              "/pdf/2024 TISCALI - ACCENDI LA MAGIA DEL NATALE.pdf",
          }}
          target="_blank"
        >
          qui
        </Link>
      </Asterisk>

      <Modal show={prizesError} close={() => setPrizesError(false)}>
        <p>
          Attenzione, è possibile selezionare al massimo due premi diversi tra
          loro
        </p>
      </Modal>
    </>
  );

  return showPrizeClicked ? (
    <ContestPrize
      prize={showPrizeClicked}
      back={() => setShowPrizeClicked(null)}
    />
  ) : (
    content
  );
};

BackHandlerSpring.propTypes = {
  action: PropTypes.object,
};

export default BackHandlerSpring;
