import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "../../components/Ui";
import Parser from "html-react-parser";
import { useParams, useHistory, Link } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import customaxios from "../../config/axios-refresh-token";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions";
import ModalMessage from "../../components/ModalMessage";
import ErrorInModal from "../../components/ErrorInModal";
import { Modal, Text } from "../../components/Ui";
import useSpring from "../../hooks/useSpring";
import {
  ChevronLeft,
  ChevronRight,
  Copy,
} from "@styled-icons/boxicons-regular";
import moment from "moment";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import useNatale from "../../hooks/useNatale";
import { Asterisk } from "../../components/Games/Handlers/BackHandler/BackHandler";

const SPContainer = styled.div`
  margin-top: 120px;

  .sp-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;
    align-items: stretch;
    ${respondTo.md`
      padding: 0;
    `}

    .sp-image {
      display: flex;
      align-items: center;
      width: 100%;
      //background-color: rgba(0, 0, 0, 0.4);
      box-shadow: ${({ spring }) =>
        spring ? "none" : "0px 5px 15px #00000026"};
      position: relative;
      border-radius: 15px;
      overflow: hidden;
      //padding: 15px;

      ${respondTo.md`
        width: calc(40% - 15px);
      `}

      & > img {
        position: ${({ spring }) => (spring ? "static" : "absolute")};
        top: 0;
        left: 0;
        width: 100%;

        z-index: 0;
        object-fit: cover;
      }

      .sp-brand {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        overflow: hidden;
        z-index: 1;
        background-color: #fff;
        display: flex;
        align-items: center;
        box-shadow: 0px 5px 15px #00000029;

        img {
          width: 100%;
        }
      }
    }

    .sp-info {
      width: 100%;
      margin-top: 30px;

      ${respondTo.md`
        width: calc(60% - 15px);
        margin-top: 0;
      `}

      .sp-header {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        img {
          margin-right: 10px;
        }

        span {
          color: #ffdf8d;
          font-weight: 300;
        }

        strong {
          color: #fdca46;
        }
      }

      .sp-body {
        .sp-brand-name {
          color: #ffdf8d;
          font-size: 18px;
        }

        .sp-brand-subtitle {
          display: block;
          font-size: 28px;
          color: #fdca46;
          margin-bottom: 10px;
        }
      }
      .sp-code {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .sp-footer {
        display: flex;
        margin-top: 50px;
        margin-top: auto;
      }
    }
  }

  .sp-coupon-success {
    width: 100%;
    background-color: #fdca46;
    border-radius: 15px;
    padding: 15px;
    margin-top: 50px;
    box-shadow: 0px 5px 15px #00000026;

    .sp-coupon-header {
      display: flex;
      justify-content: space-between;

      h3 {
        color: #fff;
        font-size: 21px;
        font-weight: 700;
      }

      .sp-coupon-brand {
        width: 70px;
        height: 70px;
        display: flex;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0px 5px 15px #00000029;
        overflow: hidden;
        align-items: center;
        justify-content: center;
      }
    }

    .coupon-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .coupon-success-left {
        width: 100%;

        ${respondTo.md`
          width: calc(45% - 20px);
        `}

        h4 {
          color: #fff;
          font-size: 28px;
          margin-bottom: 10px;
        }
      }

      .coupon-success-right {
        width: 100%;
        text-align: center;
        margin-top: 30px;

        ${respondTo.md`
          margin-top: 0;
          width: calc(55% - 20px);
        `}

        h5 {
          color: #fff;
          font-size: 18px;
          margin-bottom: 10px;
        }

        .coupon-box {
          background-color: #ffeec1;
          padding: 20px;
          border-radius: 15px;

          span {
            font-weight: 300;
            font-size: 28px;
            color: #fdca46;
          }

          .coupon-expiration {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cb9200;
            text-transform: uppercase;
            font-size: 12px;

            img {
              margin-right: 5px;
            }
          }
        }

        .coupon-footer {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          ${respondTo.md`
            flex-wrap: nowrap;
            justify-content: space-between;
          `}

          strong {
            color: #fff;
            text-transform: uppercase;
            cursor: pointer;
            margin-bottom: 15px;
            font-size: 16px;

            ${respondTo.md`
              margin-bottom: 0;
            `}

            &:hover {
              color: #ffdf8d;
            }
          }

          button {
            background-color: #fff;
            color: #fdca46;

            &:hover {
              background-color: #ffdf8d;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .coupon-terms {
    width: 100%;
    position: relative;
    top: -10px;
    background-color: #fff;
    box-shadow: 0px 5px 15px #00000026;
    padding: 30px 15px 15px 15px;
    border-radius: 15px;
    z-index: -1;

    strong {
      color: #fdca46;
      font-size: 21px;
      display: block;
      margin-bottom: 10px;
    }
  }
`;

const PrizeIw = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const natali = useNatale({ id });
  const [partner, setPartner] = useState();
  const [modalError, setModalError] = useState(false);
  const listPartners = [
    {
      id: 1,
      name: "",
      title: "Samsung",
      subtitle: "Galaxy 5G 256 Gb",
      description: `Smartphone Galaxy con display FHD+ Super AMOLED da 6,6", processore Octa-core. Colori assortiti secondo disponibilità di magazzino`,
      main_image: process.env.PUBLIC_URL + "/images/nataliPartner1.png",
      under_value: "",
      spring: true,
    },
    {
      id: 2,
      name: "",
      title: "Samsung",
      subtitle: "TV QLed 75” The Frame",
      description: `TV Qled 75 The Frame dotato di schermo con una visione ottimale senza riflessi, la cornice rende questo televisore trasformabile da spento in un quadro grazie anche alla modalità art mode per inserire immagini digitali.`,
      main_image: process.env.PUBLIC_URL + "/images/nataliPartner2.png",
      under_value: "",
      spring: true,
    },
  ];
  useEffect(() => {
    if (id) {
      let newPartner = null;

      if (natali) {
        newPartner = listPartners.find((item) => +item.id === +id);
      }
      setPartner(newPartner);
    }
  }, [id, natali]);

  const closeModalError = () => {
    setModalError(null);
  };

  return (
    <SPContainer spring={natali}>
      {partner && (
        <>
          <div className="sp-wrapper">
            <div className="sp-image">
              <img src={partner.main_image} alt="" />
            </div>

            <div className="sp-info">
              <ContentBorderBox
                position="right"
                color={natali ? "#185E14" : "#FDCA46"}
              >
                <div className="sp-code">
                  <img
                    src={process.env.PUBLIC_URL + `/images/code_img.svg`}
                    alt=""
                  />
                  <div>
                    <Text className="spring-text-code" light>
                      Codice Prodotto
                    </Text>
                    <Text className="spring-text-code-number" bold>
                      000123456789
                    </Text>
                  </div>
                </div>
                <div
                  className="sp-body"
                  style={{ marginTop: partner.spring && "20px" }}
                >
                  <span
                    className="sp-brand-name"
                    style={{
                      color: natali && "#6CB967",
                    }}
                  >
                    {partner.title}
                  </span>
                  <span
                    className="sp-brand-subtitle"
                    style={{ color: natali && "#185E14" }}
                  >
                    {partner.subtitle}
                  </span>
                  {Parser(partner.description)}
                </div>

                <div className="sp-footer">
                  <Button
                    onClick={() => history.goBack()}
                    upper
                    gradient={{ left: "#E4E7F1", right: "#ADB1BC" }}
                    hoverGradient={{ left: "#FF7DB9", right: "#E61274" }}
                  >
                    <ChevronLeft size={30} />
                    Indietro
                  </Button>
                </div>
              </ContentBorderBox>
            </div>
          </div>
          {/*           <Asterisk>
            Concorso a premi valido dal 13/04/23 al 17/04/23. Estrazione riserve
            entro il 31/05/2023. Montepremi presunto Euro 5.323,75 iva esente.
            Regolamento disponibile{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.PUBLIC_URL + "/pdf/regolamento-spring.pdf"}
            >
              qui
            </a>
          </Asterisk> */}
        </>
      )}

      <Modal show={modalError} close={closeModalError}>
        <ErrorInModal error={modalError} />
      </Modal>
    </SPContainer>
  );
};

export default PrizeIw;
