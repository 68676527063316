import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { CardBag, Click } from "../../Interactions";
import PreInteraction from "../../PreInteraction";
import ResultGame from "../../Response/ResultGame";
import styled from "styled-components";
import Avatar from "../../../Avatar/Avatar";
import { respondTo } from "../../../../theme/mixin";
import Slider from "react-slick";
import { Button, Modal } from "../../../Ui";
import * as actionCreators from "../../../../store/actions";
import { Link } from "react-router-dom";
import { CheckCircle } from "@styled-icons/boxicons-solid";
import ContestPrize from "../../../../views/ContestPrize/ContestPrize";
import useSpring from "../../../../hooks/useSpring";
import useNatale from "../../../../hooks/useNatale";
import moment from "moment";
import useSempre from "../../../../hooks/useSempre";
const CardHandlerWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 330px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 30px;
      }
    }

    .ramaining-attempts {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 30px;

      ${respondTo.md`
        margin-top: 0;
        position: absolute;
        bottom: 80px;
      `}

      .ramaining-info {
        color: #6cb967;
        margin-left: 10px;
      }
    }
  }

  .play-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 10px;

    ${respondTo.md`
      padding: 0;
    `}

    .play-box-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      z-index: 1;
      position: relative;

      h4 {
        margin-left: 10px;
        font-size: 21px;
        font-weight: 700;
      }
    }

    .background-pasqua {
      width: 100%;
      height: 100%;
    }

    .play-box-rules {
      width: 100%;
      background-color: #6cb967;
      border-radius: 15px;
      padding: 15px;

      ${respondTo.md`
        width: calc(45% - 15px);
      `}

      h4 {
        color: #fff;
      }

      p {
        line-height: 1.5;
      }

      .play-box-rules-image {
        text-align: center;
      }

      .prizes-list {
        margin: 10px 0;
        display: flex;
        gap: 15px;
        .giovediImg {
          width: 35%;
        }
        align-items: center;
      }

      .prize-icon {
        background-color: #3f943a;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: relative;
        top: -25px;
        margin: 0 auto;
        transition: all 0.2s;

        &:hover {
          background-color: #bfffbb;
          color: #3f943a;
          transition: all 0.2s;
        }

        .icon-not-selected {
          width: 20px;
          height: 20px;
          border: 2px solid #fff;
          border-radius: 50%;
        }

        &.disabled {
          opacity: 0.7;
          pointer-events: none;
        }
      }

      .prize-info {
        position: relative;
        margin-top: 15px;

        span {
          display: block;
          text-transform: uppercase;
          color: #96f290;
          font-weight: 300;
        }

        strong {
          display: inline-block;
          text-transform: uppercase;
          cursor: pointer;
          margin-top: 5px;

          &:hover {
            color: #96f290;
          }
        }

        small {
          font-weight: 700;
          font-size: 14px;
          color: #fff;
        }
      }

      &.selected {
        .prize-image {
          opacity: 0.5;
        }

        .prize-icon {
          color: #3f943a;
          background-color: #96f290;
        }
        .prize-info {
          strong {
            color: #96f290;
          }
        }
      }
    }
  }

  .play-box-game {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    //padding: 15px 30px 15px 15px;
    box-shadow: 0px 5px 15px #00000026;
    overflow: hidden;
    margin-top: 50px;
    .play-box-header {
      position: absolute;
      top: 15px;
      left: 15px;
    }
    ${respondTo.md`
        min-height: 550px;
      `}

    &:after {
      content: "";
      display: block;
      width: 10px;
      height: calc(100% - 20px);
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: #6cb967;
      border-radius: 15px;
    }
    /*  &:before {
      content: "";
      display: block;
      width: 10px;
      height: calc(100% - 20px);
      position: absolute;
      right: 0;
      top: 10px;
      background-color: #fff;
      z-index: 1;
    } */

    ${respondTo.md`
        width: calc(55% - 15px);
        margin-top: 0;
      `}

    h4 {
      color: #6cb967;
    }

    .back-desktop {
      position: relative;

      &.hasWon {
        opacity: 0.3;
      }

      ${respondTo.md`
          position: absolute;
          top: 60px;
          left: 15px;
          width: calc(100% - 50px);
        `}
    }

    .bag-prizes {
      position: absolute;
      left: 75px;
      z-index: 10;
      display: flex;
      margin-top: 35px;

      ${respondTo.md`
          left: 155px;
        `}

      .bag-prize {
        position: relative;
        margin: 0 3px;

        .bag-remove {
          position: absolute;
          top: 0;
          right: 0;
          width: 27px;
          height: 27px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #b73e27;
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
        }

        .bag-image {
          overflow: hidden;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 0px 5px 15px #00000029;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: auto;
            height: 45%;
          }
        }
      }
    }

    .play-box-background {
      position: absolute;
      top: 20px;
      left: 0;
      width: calc(100% - 20px);
      z-index: 0;
    }
  }

  .privacy-consens {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    z-index: 1;

    h5 {
      color: #6cb967;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 10px;
    }

    span {
      font-size: 12px;
    }

    .privacy-checkbox {
      background-color: #fff;
      box-shadow: 0px 5px 15px #00000026;
      border-radius: 15px;
      width: 270px;
      height: 44px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;

      a {
        color: #185e14;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }
`;

const Asterisk = styled.small`
  font-size: 10px;
  color: #adb1bc;
  display: block;
  margin-top: 20px;
  line-height: 1.5;
  padding: 0 15px;
  z-index: 1;

  ${respondTo.md`
    padding: 0;
  `}

  a {
    color: #eb5a3f;
    text-decoration: underline;
  }
`;

const ClickHandler = (props) => {
  const dispatch = useDispatch();

  const {
    g: {
      id,
      preinteraction,
      log: { steps, won },
      gamePrivacyAccepted,
    },
    played,
    action,
  } = props;
  const halloween = useNatale({ id });
  const sempre = useSempre({ id });
  const options = {
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const interactionData = JSON.parse(props.g.interaction.data);
  const prizesListObj = interactionData && interactionData.i18l.it.prizes;

  var prizesList = Object.keys(prizesListObj ? prizesListObj : {}).map(
    (key, index) => {
      return { id: key, ...prizesListObj[key] };
    }
  );

  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }

  const [showPrizeClicked, setShowPrizeClicked] = useState(null);
  const [prizes, setPrizes] = useState([]);
  const [prizesError, setPrizesError] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction); // true or false in base al fatto che ci sia la prop preinteraction
  const [userHasWon, setUserHasWon] = useState(null);
  const [prizeFromIw, setPrizeFromIw] = useState();

  const remaining_attempts = useSelector(
    (state) => state.promo.game[0].remaining_attempts
  );
  const currentUser = useSelector((state) => state.user.user.userinfo);

  let content = null;

  const onShowResult = () => setShowResult(true);

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };

  const retryHandler = () => {
    dispatch(actionCreators.getGame(id, "vincisempredipiu", ""));
  };

  const managePrizesHandler = (prizeToAdd) => {
    let newPrizes = [...prizes];

    const alreadyInPrizes = newPrizes.find(
      (prize) => prize.id === prizeToAdd.id
    );

    if ((!played || showResult) && gamePrivacyAccepted) {
      if (prizes.length < 2 && !alreadyInPrizes) {
        newPrizes.push(prizeToAdd);
      } else {
        setPrizesError(true);
      }
    }

    setPrizes(newPrizes);
  };

  const removePrizeHandler = (index) => {
    let newPrizes = [...prizes];
    newPrizes.splice(index, 1);
    setPrizes(newPrizes);
  };

  const acceptGameRulesHandler = () => {
    dispatch(actionCreators.acceptGameRules(id));
  };
  const game = useSelector((state) => state.promo.game[0]);
  useEffect(() => {
    if (action.data[0]) {
      setUserHasWon(action.data[0].won);
      setPrizeFromIw(action.data[0].prize);
    }
  }, [action]);
  //check date now and date change game images
  let dataChangeGame = moment("2024-03-28T00:00:00");
  let now = moment();
  let newVinciPrize = false;
  if (now.isBefore(dataChangeGame)) {
  } else if (now.isAfter(dataChangeGame)) {
    newVinciPrize = true;
  } else {
    newVinciPrize = true;
  }

  content = (
    <>
      <CardHandlerWrapper>
        <div className="slide">
          <img
            src={
              game.id === +process.env.REACT_APP_GAME_NATALE
                ? process.env.PUBLIC_URL + "/images/bg_natale_slide.png"
                : game.id === +process.env.REACT_APP_GAME_SEMPRE
                ? process.env.PUBLIC_URL + "/images/back-to-school-game.png"
                : process.env.PUBLIC_URL + "/images/bg_participa.png"
            }
            alt="slide"
          />
          <div className="content">
            {game.id === +process.env.REACT_APP_GAME_NATALE ? (
              <div>
                <h3>{game.i18l.info.title}</h3>
                <p>
                  Halloween si avvicina: partecipa al nostro concorso{" "}
                  <strong>
                    {" "}
                    dal 27 al 31 ottobre, bussa alla porta della casa stregata e
                    prova a vincere!
                  </strong>{" "}
                  Ogni giorno in palio per te una{" "}
                  <strong>Gift card digitale Best Western da 600€</strong>.
                </p>
              </div>
            ) : game.id === +process.env.REACT_APP_GAME_SEMPRE ? (
              <div>
                <h3>Partecipa al concorso Vinci sempre di +</h3>
                <p>
                  Quest’anno <strong>MyLinkPlus</strong> ti premia sempre di +:{" "}
                  <strong>ogni lunedì, dal 02/09/2024 al 09/12/2024</strong>,
                  prova a vincere <strong>un frullatore Kitchen Aid</strong>.
                  <br />
                  <br />
                  In più, parteciperai all’estrazione finale di{" "}
                  <strong>1 Voucher Eataly del valore di 4.000 €</strong> per
                  assaporare al meglio tutte le eccellenze italiane.
                </p>
              </div>
            ) : (
              <div>
                <h3>Partecipa al concorso Vinci ancora di +</h3>
                <p>
                  Quest'anno <strong>MYLINKPLUS</strong> ti premia molto di +:
                  dal <strong>04/09/2023 </strong> al{" "}
                  <strong>27/06/2024</strong> prova a vincere{" "}
                  <strong>ogni lunedì un Buono Regalo Amazon.it*</strong> e{" "}
                  <strong>ogni giovedì fantastici premi!</strong>
                  <br />
                  <br />
                  In più, parteciperai all'estrazione finale di{" "}
                  <strong>
                    {" "}
                    1 Voucher Alpitour World del valore di 5000€
                  </strong>{" "}
                  per una vacanza da sogno.
                </p>
              </div>
            )}

            {currentUser && (
              <div className="ramaining-attempts">
                <Avatar shadow />
                <div className="ramaining-info">
                  {currentUser.firstname}, hai
                  <br />
                  <strong>
                    {remaining_attempts === null ? 0 : remaining_attempts}{" "}
                    possibilità di gioco
                  </strong>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="play-box-wrapper">
          <div className="play-box-rules">
            <div className="play-box-header">
              <img
                src={process.env.PUBLIC_URL + "/icons/play-icon.svg"}
                alt=""
              />
              <h4>Come partecipare al concorso</h4>
            </div>
            {halloween ? (
              <p>
                Accetta il regolamento,{" "}
                <strong>clicca sulla porta e scopri subito se hai vinto</strong>
                .
                <br /> Per visionare le caratteristiche del premio in palio,
                clicca sull’immagine qui sotto.
              </p>
            ) : sempre ? (
              <p>
                È finalmente <strong>lunedì</strong>, il giorno perfetto per
                provare a vincere un <strong>tostapane retrò KitchenAid</strong>
                .
                <br />
                <br />
                <strong>Clicca sulla sfera di cristallo</strong> e scopri subito
                se hai vinto il <br /> premio in palio. <br /> Ricorda che,
                anche se non hai vinto, parteciperai all’estrazione finale di{" "}
                <strong>1 Voucher Eataly del valore di 4.000€</strong> per
                gustare le eccellenze della cucina italiana.
              </p>
            ) : new Date().getDay() === +process.env.REACT_APP_MONDAY_DAY ? (
              <p>
                È finalmente <strong>lunedì</strong>, prova a vincere un{" "}
                <strong>Buono Regalo Amazon.it* da 150€</strong>.<br />
                <br />
                Benvenuto al Luna Park!{" "}
                <strong>Clicca sulla craw machine</strong>, prova a prendere un
                pacchetto e scopri subito se hai vinto!
                <br />
                Ricorda che, anche se non hai vinto, parteciperai all’estrazione
                finale di{" "}
                <strong>
                  1 Voucher Alpitour World del valore di 5.000€
                </strong>{" "}
                per una vacanza da sogno.
              </p>
            ) : (
              <p>
                <strong>È arrivato giovedì</strong>, prova a vincere un{" "}
                {newVinciPrize ? " paio di " : ""}{" "}
                <strong>
                  {newVinciPrize ? "Cuffie on ear BOSE!" : "Microonde Sage!"}
                </strong>
                <br />
                <br />
                Benvenuto al Luna Park!{" "}
                <strong>Clicca sulla piramide di barattoli</strong>, prova a
                farli cadere tutti e scopri se hai vinto!
                <br />
                <br />
                Ricorda che, anche se non hai vinto, parteciperai all’estrazione
                finale di{" "}
                <strong>
                  1 Voucher Alpitour World del valore di 5000€
                </strong>{" "}
                per una vacanza da sogno.
              </p>
            )}
            <div className="prizes-list">
              {halloween ? (
                <Link to="/prize/1" className="giovediImg halloween">
                  <img
                    src={process.env.PUBLIC_URL + "/images/halloweenPromo.png"}
                    alt=""
                    width={160}
                    height={160}
                  />
                </Link>
              ) : (
                <img
                  className="giovediImg"
                  src={
                    halloween
                      ? process.env.PUBLIC_URL + "/images/halloweenPromo.png"
                      : sempre
                      ? process.env.PUBLIC_URL +
                        "/images/nextdevelopment.it-7.png"
                      : new Date().getDay() ===
                        +process.env.REACT_APP_MONDAY_DAY
                      ? process.env.PUBLIC_URL + "/images/lunediParticipa.png"
                      : newVinciPrize
                      ? process.env.PUBLIC_URL +
                        "/images/giovediPartecipaNew.png"
                      : process.env.PUBLIC_URL + "/images/giovediPartecipa.png"
                  }
                  alt=""
                />
              )}

              <div>
                <p style={{ color: "#96F290" }}>
                  {halloween
                    ? "Best Western"
                    : sempre
                    ? "Kitchen Aid"
                    : new Date().getDay() === +process.env.REACT_APP_MONDAY_DAY
                    ? "Amazon"
                    : newVinciPrize
                    ? "BOSE"
                    : "SAGE"}
                </p>
                {halloween ? (
                  <p>
                    <strong>GIFT CARD DIGITALE DA 600 €</strong>
                  </p>
                ) : sempre ? (
                  <strong>1 FRULLATORE</strong>
                ) : new Date().getDay() ===
                  +process.env.REACT_APP_MONDAY_DAY ? (
                  <p>
                    <strong>BUONO REGALO</strong>
                    <br />
                    <strong>AMAZON.IT* DA EURO 150</strong>
                  </p>
                ) : (
                  <p>
                    <strong>
                      {newVinciPrize ? "CUFFIE ON EAR" : "MICROONDE"}
                    </strong>
                  </p>
                )}
              </div>
            </div>
            {halloween ? (
              <p>
                <strong>Se non hai vinto, non preoccuparti. </strong>
                <br />
                Puoi sempre ritentare domani. <br />
                Ricorda che da + anni sei cliente Linkem, + tentativi hai a
                disposizione.
              </p>
            ) : sempre ? (
              <p>
                <strong>Se non hai vinto, non preoccuparti!</strong>
                <br /> Puoi ritentare ogni lunedì.
                <br /> <strong>MYLINKPLUS premia la tua fedeltà:</strong>
                <br />
                Ricorda che da più anni sei cliente Linkem più tentativi hai a
                disposizione
              </p>
            ) : (
              <p>
                <strong>Se non hai vinto, non preoccuparti! </strong>
                <br />
                Puoi ritentare{" "}
                {new Date().getDay() === +process.env.REACT_APP_MONDAY_DAY
                  ? "ogni lunedì."
                  : "ogni giovedì."}
                <br />
                <strong>MYLINKPLUS premia la tua fedeltà:</strong> <br />
                ricorda che da + anni sei cliente Linkem + tentativi hai a
                disposizione
              </p>
            )}
          </div>
          <div className="play-box-game">
            <div className="play-box-header">
              <img
                src={process.env.PUBLIC_URL + "/icons/play-icon.svg"}
                alt=""
              />
              <h4>Gioca</h4>
            </div>
            {/*  {!showResult && true && ( */}
            {!showResult && !played && !halloween && !sempre && (
              <img
                className="background-pasqua"
                src={
                  new Date().getDay() === +process.env.REACT_APP_MONDAY_DAY
                    ? process.env.PUBLIC_URL + "/images/bg_gameLunedi.png"
                    : process.env.PUBLIC_URL + "/images/bg_gameGiovedi.png"
                }
                alt=""
              />
            )}
            {!showResult && !played && !gamePrivacyAccepted && halloween && (
              <img
                className="background-pasqua"
                src={process.env.PUBLIC_URL + "/images/halloweenClick.png"}
                alt=""
              />
            )}
            {!showResult && !played && gamePrivacyAccepted && halloween && (
              <img
                className="background-pasqua"
                src={process.env.PUBLIC_URL + "/images/halloweenClickStart.png"}
                alt=""
              />
            )}
            {!showResult && !played && !gamePrivacyAccepted && sempre && (
              <img
                className="background-pasqua"
                src={process.env.PUBLIC_URL + "/images/sempre_click.png"}
                alt=""
              />
            )}
            {!showResult && !played && gamePrivacyAccepted && sempre && (
              <img
                className="background-pasqua"
                src={process.env.PUBLIC_URL + "/images/sempre_click.png"}
                alt=""
              />
            )}
            <>
              {/* {!userHasWon && (
              <div className="bag-prizes">
                {!userHasWon &&
                  prizes.map((prize, index) => (
                    <div className="bag-prize" key={index}>
                      {!showResult && (
                        <div
                          className="bag-remove"
                          onClick={() => removePrizeHandler(index)}
                        >
                          <X size={18} />
                        </div>
                      )}
                      <div className="bag-image">
                        <img src={prize.media.src} alt="" />
                      </div>
                    </div>
                  ))}
              </div>
            )} */}

              {/* {userHasWon || won ? (
              <img
                className="back-bag bag-close"
                src={
                  process.env.PUBLIC_URL +
                  "/images/back-to-school-bag-close.svg"
                }
                alt=""
              />
            ) : (
              <img
                className="back-bag"
                src={process.env.PUBLIC_URL + "/images/back-to-school-bag.svg"}
                alt=""
              />
            )} */}
              {/*  {showResult ? ( */}
            </>
            {showResult || played ? (
              <ResultGame
                type="back-to-school"
                retry={() => retryHandler()}
                remaining_attempts={remaining_attempts}
                prizeFromIw={prizeFromIw}
                {...props}
              />
            ) : !showInteraction && steps.preinteraction.data === null ? (
              <PreInteraction
                preinteraction={preinteraction}
                onShowInteractionHandler={onShowInteractionHandler}
                {...props}
              />
            ) : (
              <Click
                remaining_attempts={remaining_attempts}
                canPlay={true}
                type="back-to-school"
                typeInteraction="interaction"
                onShowResult={onShowResult}
                halloween={halloween ? true : false}
                sempre={sempre ? true : false}
                {...props}
              />
            )}
            {/* <img
              className={`back-desktop ${userHasWon || won ? "hasWon" : null}`}
              src={
                process.env.PUBLIC_URL +
                `/images/${
                  userHasWon || won
                    ? "contest-halloween-play-lose-background.svg"
                    : "contest-halloween-play-background.png"
                }`
              }
              alt=""
            /> */}

            {!gamePrivacyAccepted &&
              (!halloween && !sempre ? (
                <div className="privacy-consens">
                  <div className="privacy-content">
                    <h5>
                      Accetta il regolamento per
                      <br />
                      partecipare al concorso
                      <br />
                      "Vinci ancora di più"
                    </h5>
                    <span>
                      Dichiaro di aver <strong>letto</strong> e{" "}
                      <strong>accettato</strong>
                    </span>
                    <div className="privacy-checkbox">
                      <a
                        href={
                          process.env.PUBLIC_URL +
                          "/pdf/Regolamento_Linkem_Vinci_ancora_di_più.pdf"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Regolamento
                      </a>
                      <Button
                        small
                        gradient={{ left: "#6CB967", right: "#96F290" }}
                        hoverGradient={{ left: "#96F290", right: "#96F290" }}
                        onClick={acceptGameRulesHandler}
                      >
                        Accetto
                      </Button>
                    </div>
                  </div>
                </div>
              ) : sempre ? (
                <div className="privacy-consens">
                  <div className="privacy-content">
                    <h5>
                      Accetta il regolamento per
                      <br />
                      partecipare al concorso
                      <br />
                      "Vinci sempre di più"
                    </h5>
                    <span>
                      Dichiaro di aver <strong>letto</strong> e{" "}
                      <strong>accettato</strong>
                    </span>
                    <div className="privacy-checkbox">
                      <a
                        href={
                          process.env.PUBLIC_URL +
                          "/pdf/2024 TISCALI - VINCI SEMPRE DI PIU co rushwin + instant win_rev privacy_04.07.2024 DEF (2).pdf"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Regolamento
                      </a>
                      <Button
                        small
                        gradient={{ left: "#6CB967", right: "#96F290" }}
                        hoverGradient={{ left: "#96F290", right: "#96F290" }}
                        onClick={acceptGameRulesHandler}
                      >
                        Accetto
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="privacy-consens">
                  <div className="privacy-content">
                    <h5>
                      Accetta il regolamento per <br /> partecipare al concorso{" "}
                      <br /> “Trick or gift?”
                    </h5>
                    <div className="privacy-checkbox">
                      <a
                        href={
                          process.env.PUBLIC_URL +
                          "/pdf/regolamento_natale_2023.pdf"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Regolamento
                      </a>
                      <Button
                        small
                        gradient={{ left: "#6CB967", right: "#96F290" }}
                        hoverGradient={{ left: "#96F290", right: "#96F290" }}
                        onClick={acceptGameRulesHandler}
                      >
                        Accetto
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </CardHandlerWrapper>
      {halloween ? (
        <Asterisk>
          Concorso a premi valido dal 27/10/23 al 31/10/23. Estrazione riserve
          entro il 13/11/23. Montepremi presunto Euro 3.000,00 iva inclusa.
          Regolamento disponibile su{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkem.com"
          >
            www.linkem.com
          </a>
        </Asterisk>
      ) : sempre ? (
        <Asterisk>
          Concorso “Vinci sempre di più”, valido dal 22.07.2024 al 30.06.2025
          suddiviso in 2 fasi: Rush&Win dal 22/07/2024 al 25/08/2024 e Instant
          Win dal 02/09/2024 al 30/06/2025. Stima valore montepremi complessivo
          € 33.279,59 + iva ove dovuta. Regolamento su{" "}
          <Link
            to={{
              pathname:
                process.env.PUBLIC_URL +
                "/pdf/2024 TISCALI - VINCI SEMPRE DI PIU co rushwin + instant win_rev privacy_04.07.2024 DEF (2).pdf",
            }}
            target="_blank"
          >
            mylinkplus.linkem.com
          </Link>
        </Asterisk>
      ) : (
        <Asterisk>
          Concorso a premi valido complessivamente dal 17/07/23 al 27/06/24.
          Prima estrazione entro il 15/09/23 ed estrazione finale entro il
          09/07/24. Montepremi 56.127,15 IVA inclusa ove presente. Regolamento
          disponibile{" "}
          <Link
            to={{
              pathname:
                process.env.PUBLIC_URL +
                "/pdf/Regolamento_Linkem_Vinci_ancora_di_più.pdf",
            }}
            target="_blank"
          >
            qui
          </Link>
          <br /> *Restrizioni applicate. Vedere dettagli su:{" "}
          <a
            href="https://www.amazon.it/gc-legal"
            target="_blank"
            rel="noopener noreferrer"
          >
            amazon.it/gc-legal
          </a>
          .{" "}
        </Asterisk>
      )}

      <Modal show={prizesError} close={() => setPrizesError(false)}>
        <p>
          Attenzione, è possibile selezionare al massimo due premi diversi tra
          loro
        </p>
      </Modal>
    </>
  );

  return showPrizeClicked ? (
    <ContestPrize
      prize={showPrizeClicked}
      back={() => setShowPrizeClicked(null)}
    />
  ) : (
    content
  );
};

ClickHandler.propTypes = {
  action: PropTypes.object,
};

export default ClickHandler;
