import React from "react";
import { useParams } from "react-router";
import styled, { css } from "styled-components/macro";
import useNatale from "../../../../hooks/useNatale";
import useSpring from "../../../../hooks/useSpring";
import { respondTo } from "../../../../theme/mixin";
import { Wrapper, Button, Typography, Text } from "../../../Ui";
import { useSelector } from "react-redux";
import { useState } from "react";
import useCarnevale from "../../../../hooks/useCarnevale";
import useSempre from "../../../../hooks/useSempre";
const ResultTitle = styled.p`
  color: ${(props) =>
    props.carnevale
      ? props.userWon
        ? "#3a3c42"
        : "#000"
      : props.spring
      ? props.userWon
        ? "#3a3c42"
        : "#000"
      : props.sempre
      ? props.userWon
        ? "#3a3c42"
        : "#000"
      : props.natale
      ? props.userWon
        ? "#000"
        : "#000"
      : props.userWon
      ? "#3F943A"
      : "#b73e27"};

  ${({ sempre }) =>
    sempre &&
    css`
      .text-center-win {
        font-size: 18px;
      }
    `};

  font-size: 14px !important;
  font-weight: 300;
  position: absolute;
  top: 42px;
  left: 0;
  right: 15px;
  text-align: left;
  z-index: 1;
  padding-right: 10px;
  ${({ carnevale, spring }) =>
    (carnevale || spring) &&
    css`
      top: auto;
      width: 60%;
      left: 50%;
      bottom: 42px;
      transform: translateX(-50%);
      text-align: center;
      font-size: 12px !important;
    `}

  ${({ natale }) =>
    natale &&
    css`
      top: auto;
      bottom: 60px;
      left: 10%;
      .text-center-win {
        width: 100%;
        margin: 0 auto;

        ${respondTo.sm`
    width: 50%;
    `}
      }
    `}

  ${respondTo.sm`
       font-size: 20px !important;
       ${({ carnevale, spring }) =>
         (carnevale || spring) &&
         css`
           //  top: 70px;
           width: 60%;
           left: 50%;
           transform: translateX(-50%);
           text-align: center;
           font-size: 22px !important;
           bottom: 65px;
         `}
    `}
`;

const ResultDynamic = (props) => {
  const {
    userWon,
    type,
    remaining_attempts,
    prize,
    prizeFromIw,
    dynamicData: { title, content },
  } = props;
  const { id } = useParams();
  const spring = useSpring({ id });
  const natale = useNatale({ id });
  const carnevale = useCarnevale({ id });
  const sempre = useSempre({ id });
  const { start, end } = useSelector(
    (state) =>
      state.promo.allpromo
        .find((item) => item.name === "vinciancoradipiu")
        ?.games.find((item) => +item.id === +id)?.settings || {}
  );
  const end1 = new Date(end?.replace(/-/g, "/"));
  const today = new Date();
  today.setHours(23, 59, 59, 999);

  end1.setMinutes(0, 0, 0);
  today.setMinutes(0, 0, 0);

  const end1Stamp = end1.getTime();
  const todayStamp = today.getTime();

  return (
    <ResultTitle
      userWon={userWon}
      spring={spring}
      natale={natale}
      carnevale={carnevale}
      sempre={sempre}
      remaining_attempts={remaining_attempts}
    >
      <>
        {/* userWon */}
        {userWon ? (
          <div className="text-center text-center-win">
            <strong>Complimenti!</strong>
            <br />
            <Text>
              {prize && prize.title
                ? prize.title
                : prizeFromIw && prizeFromIw.title
                ? prizeFromIw.title
                : ""}
            </Text>
            {/*    <p>Hai vinto un frullatore </p>
            <p>Kitchen Aid</p> */}
          </div>
        ) : (
          <div className="text-center mt-10">
            {remaining_attempts > 0 ? (
              carnevale || spring || sempre ? (
                <>
                  Peccato, questa volta
                  <br /> non hai vinto.
                  <br />
                  Prova ancora!
                </>
              ) : (
                <>Peccato, questa volta non hai vinto.</>
              )
            ) : carnevale || spring || sempre || natale ? (
              <>
                <strong>
                  Peccato,
                  <br /> oggi non hai vinto. <br />
                  {end1.getDate() > new Date().getDate() && "Ritenta domani!"}
                  {natale && "Ritenta domani!"}
                </strong>
              </>
            ) : (
              <>
                Peccato, questa settimana <br /> non hai vinto.
                <br /> Ritenta il prossimo{" "}
                {new Date().getDay() === +process.env.REACT_APP_MONDAY_DAY
                  ? "lunedì!"
                  : "giovedì!"}
              </>
            )}
          </div>
        )}
      </>
    </ResultTitle>
  );
};

export default ResultDynamic;
