import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import Code from "../Code";
import Prize from "../Prize";
import Form from "../../Form";
import { useSelector } from "react-redux";
import ResultDynamic from "../ResultDynamic";
import Fade from "../../../Fade";
import { Button } from "../../../Ui";
import { getElement } from "../../../../utils/utils";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { ChevronRight, X } from "@styled-icons/boxicons-regular";
import { respondTo } from "../../../../theme/mixin";
import { isWinExpires } from "../../../../utils/utils";
import useSpring from "../../../../hooks/useSpring";
import useNatale from "../../../../hooks/useNatale";
import useCarnevale from "../../../../hooks/useCarnevale";
import useSempre from "../../../../hooks/useSempre";

const ResultContainer = styled.div`
  // width: calc(100% - 30px);
  background: ${(props) =>
    props.type === "back-to-school"
      ? "transparent"
      : "rgba(255, 255, 255, 0.7)"};
  /*  position: ${(props) =>
    props.type === "back-to-school" ? "relative" : "absolute"}; */
  // left: ${(props) => (props.type === "back-to-school" ? "30px" : 0)};
  //top: 0;
  //padding-top: ${(props) => (props.type === "back-to-school" ? 0 : "70px")};
  //padding-right: 10px;
  text-align: ${(props) =>
    props.type === "back-to-school" ? "left" : "center"};
  /*   height: ${(props) =>
    props.type === "back-to-school" ? "auto" : "calc(100% - 100px)"}; */
  z-index: 1;
  height: 100%;
  ${respondTo.md`
   // padding-top: 70px;
   // position: absolute;
  `}

  .retry-game-bts {
    p {
      text-align: center;
    }

    button {
      color: #fff;
      position: absolute;
      top: 110px;
      left: 50%;
      transform: translateX(-50%);
      ${respondTo.md`
   top: 160px;
   
  `}
      ${respondTo.sm`
   top: 121px;
     
  `}
   ${({ sempre }) => {
        return (
          sempre &&
          css`
            bottom: 10px;
            top: initial;
            ${respondTo.md`
   top: initial;
   
  `}
            ${respondTo.sm`
   top: initial;
     
  `}
          `
        );
      }};

      ${({ spring }) => {
        return (
          spring &&
          css`
            font-size: 10px;
            left: 50%;
            bottom: 10px;
            transform: translateX(-50%);
            height: auto;
            top: unset;
            ${respondTo.sm`
            height: 45px;
            font-size:16px;
            bottom: 10px;
            top: unset;
            `}
          `
        );
      }};
      ${({ carnevale }) => {
        return (
          carnevale &&
          css`
            font-size: 10px;
            left: 50%;
            top: auto;
            bottom: 10px;
            transform: translateX(-50%);
            height: auto;
            ${respondTo.md`
   top: auto;
   
  `}
            ${respondTo.sm`
            height: 45px;
            font-size:16px;
              top:auto;
            `}
          `
        );
      }};
      ${({ natale }) =>
        natale &&
        css`
          top: initial;
          bottom: 10px;
          ${respondTo.sm`
            top: initial;
          bottom: 10px;
            `}
        `}
    }
  }
`;

const GameWrapper = styled.div`
  // margin: 20px 0;
  width: ${(props) => (props.type === "back-to-school" ? "100%" : "auto")};
  height: 100%;
  img {
    display: block;
    // margin-top: 30px;
  }

  p {
    font-size: ${(props) =>
      props.type === "back-to-school" ? "24px" : "auto"};
  }
  .bg_lose {
    opacity: 0.6;
  }
  .loose_spring {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
  }
  .retry-game-bts {
    button {
      z-index: 1;
      white-space: nowrap;
    }
  }
  ${respondTo.md`
    margin: 0;
    margin-top: ${(props) =>
      props.type === "back-to-school" ? "0" : "inherith"};
    width: ${(props) => (props.type === "back-to-school" ? "100%" : "auto")};
    text-align: ${(props) =>
      props.type === "back-to-school" ? "center" : "inherith"};

    img {
      margin-top: 0;
    }
  `}
`;

const GameWin = styled.div`
  //display: flex;
  //justify-content: center;
  //margin-top: 10px;
  .btn-win {
    left: 50%;
    bottom: 10px;
    top: initial;
    transform: translateX(-50%);
    ${respondTo.sm`

      top: 140px;
      bottom:initial;
    left: 32%;
     transform: initial;
      ${({ natale }) =>
        natale &&
        css`
          bottom: 10px;
          top: auto;
        `}
    `}
  }
  height: 100%;
  button {
    color: #fff;
    position: absolute;
    top: 125px;
    left: 32%;

    z-index: 99;

    ${({ spring }) =>
      spring &&
      css`
        top: unset;
        bottom: 10px;
        height: auto;
        font-size: 12px;
        left: 50%;
        transform: translateX(-50%);
        ${respondTo.sm`
     font-size: 14px;
     height: 45px;
     top: unset;
     bottom: 10px;
    `}
      `}
    ${({ carnevale }) =>
      carnevale &&
      css`
        top: auto;
        bottom: 10px;
        height: auto;
        font-size: 12px;
        left: 50%;
        transform: translateX(-50%);
        ${respondTo.sm`
     font-size: 14px;
     height: 45px;
     top: auto;
    `}
      `}
      ${({ natale }) =>
      natale &&
      css`
        z-index: 0;
        left: 50%;
        bottom: 10px;
        top: initial;
        transform: translateX(-50%);
        white-space: nowrap;
      `}
  }
  .imgPrize {
    position: absolute;
    top: 44%;
    width: 90px;
    left: 50%;
    transform: translateX(-50%);
    ${respondTo.sm`
      width: 139px;
      top: 47%;
    `}
  }
  .thunders {
    position: absolute;
    bottom: 0;
    opacity: 0;
    animation: thunders-move 0.3s 1s linear forwards;

    ${respondTo.md`
      right: 200px;
    `}
  }

  @keyframes thunders-move {
    from {
      opacity: 0;
      margin-bottom: 20px;
    }
    to {
      opacity: 1;
      margin-bottom: -25px;
    }
  }

  .bike {
    display: none;
    position: absolute;
    left: 100%;
    margin-left: -150px;
    animation: bike-move 1s linear;

    ${respondTo.md`
      display: block;
    `}
  }

  @keyframes bike-move {
    from {
      left: 50%;
    }
    to {
      left: 100%;
    }
  }
`;

const RetryGame = styled.div`
  position: absolute;
  top: 126px;
  left: 30%;
  button {
    color: #fff;
  }
`;

const GameImage = styled.div`
  display: flex;
  justify-content: center;

  .thunder {
    position: absolute;
    margin-top: 60px;
    margin-left: 20px;
    width: 40px;

    ${respondTo.md`
      width: 55px;
    `}
  }

  .bike {
    width: 300px;

    ${respondTo.md`
      width: 370px;
    `}
  }
`;

const GameMapImage = styled.div`
  position: relative;
`;

const FormContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .close-form {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #6cb967;
    position: fixed;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0px 5px 15px #00000026;
  }

  .form-content {
    background-color: #fff;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0 30px;

    ${respondTo.md`
      max-width: 80%;
      height: 80%;
      max-height: 90%;
      border-radius: 15px;
    `}
  }
`;

const ResultGame = (props) => {
  const {
    g: {
      id,
      interaction: { data: rules },
      log,
      log: { steps, won, code, error, form: formResponse, prize },
      i18l,
      form,
    },
    lang,
    played,
    action,
    action: { cleanData },
    type,
    retry,
    remaining_attempts,
    prizeFromIw,
  } = props;
  const spring = useSpring({ id });
  const carnevale = useCarnevale({ id });
  const natale = useNatale({ id });
  const sempre = useSempre({ id });
  useEffect(() => {
    return () => {
      cleanData && cleanData(id);
    };
  }, []);

  const pending_list = useSelector((state) => state.user.user.wins);

  const [gamePending, setGamePending] = useState(null);
  const [gamePendingExpires, setGamePendingExpires] = useState(true);

  useEffect(() => {
    if (pending_list && id) {
      const findGamePending = pending_list.find(
        (win) => win.game_id === id && win.pending === true
      );

      if (findGamePending) {
        if (type === "back-to-school") {
          setGamePendingExpires(isWinExpires(findGamePending.datetime));
        }
        setGamePending(findGamePending);
      }
    }
  }, [pending_list, id]);

  let theData = {};
  let theProfileData = action.data ? getElement(action.data, id) : {};

  if (played) {
    theData = steps.interaction.data;
  } else {
    theData = theProfileData?.data ? theProfileData?.data : null;
  }

  let rulesData = JSON.parse(rules);
  let logData = theData ? JSON.parse(theData) : null;

  let theCodeData = {};

  if (theProfileData) {
    theCodeData = {
      code: theProfileData.code,
      error: theProfileData.error,
    };
  } else {
    theCodeData = { code: code, error: error };
  }

  let playslog = theProfileData ? theProfileData.playslog : log.playslog;
  let userWon = theProfileData ? theProfileData.won : won;

  let resultDynamic = null;
  if (userWon) {
    resultDynamic = "win";
  } else {
    resultDynamic = "loose";
  }

  useEffect(() => {
    if (userWon) {
      if (userWon === 0) {
        ReactGA.event({
          category: "Game",
          action: "Perdita",
        });
      } else if (userWon === 1) {
        ReactGA.event({
          category: "Game",
          action: "Vincita",
        });
      }
    }
  }, [userWon]);

  let dynamicData = i18l[resultDynamic];

  const [isConvalidaOpen, setIsConvalidaOpen] = useState(false);

  return (
    <>
      {isConvalidaOpen && userWon && form?.type === "post" ? (
        <FormContainer>
          <div className="close-form" onClick={() => setIsConvalidaOpen(false)}>
            <X size={30} />
          </div>
          <div className="form-content">
            <Form
              form={form}
              formResponse={formResponse}
              lang={lang}
              id={id}
              playslog={playslog}
              played={played}
            />
          </div>
        </FormContainer>
      ) : (
        <ResultContainer
          type={type}
          spring={spring}
          natale={natale}
          carnevale={carnevale}
          sempre={sempre}
        >
          <GameWrapper type={type}>
            {won !== null || theProfileData.won !== null ? (
              <ResultDynamic
                dynamicData={dynamicData}
                userWon={userWon}
                type={type}
                remaining_attempts={remaining_attempts}
                prize={prize}
                prizeFromIw={prizeFromIw}
              />
            ) : null}
            {/*  userWon */}
            {userWon ? (
              <>
                <GameWin
                  type={type}
                  spring={spring}
                  natale={natale}
                  carnevale={carnevale}
                  sempre={sempre}
                >
                  {/* gamePending */}
                  {gamePending && (
                    <Button
                      upper
                      gradient={{ left: "#6CB967", right: "#96F290" }}
                      hoverGradient={{ left: "#96F290", right: "#96F290" }}
                      onClick={() => setIsConvalidaOpen(true)}
                      style={spring ? { fontWeight: "600" } : {}}
                      className="btn-win"
                    >
                      {spring
                        ? "Richiedile subito"
                        : type === "back-to-school"
                        ? "Richiedilo subito"
                        : dynamicData.extra}
                      <ChevronRight size={30} />
                    </Button>
                  )}
                  {carnevale ? (
                    <img
                      className="background-pasqua"
                      src={process.env.PUBLIC_URL + "/images/carnevaleWin.png"}
                      alt=""
                    />
                  ) : sempre ? (
                    <img
                      className="background-pasqua"
                      src={process.env.PUBLIC_URL + "/images/sempre_win.png"}
                      alt=""
                    />
                  ) : spring ? (
                    <img
                      className="background-pasqua"
                      src={process.env.PUBLIC_URL + "/images/springWin.png"}
                      alt=""
                    />
                  ) : natale ? (
                    <img
                      className="background-pasqua"
                      src={process.env.PUBLIC_URL + "/images/nataleWin.png"}
                      alt=""
                    />
                  ) : new Date().getDay() ===
                    +process.env.REACT_APP_MONDAY_DAY ? (
                    <img
                      className="background-pasqua"
                      src={
                        process.env.PUBLIC_URL + "/images/bg_gameLunedi_win.png"
                      }
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <img
                        className="background-pasqua"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/bg_gameGiovedi_win.png"
                        }
                        alt=""
                      />
                      {/* <img
                        className="imgPrize"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/giovediPartecipa.png"
                        }
                        alt=""
                      /> */}
                    </>
                  )}
                </GameWin>
              </>
            ) : (
              <>
                {remaining_attempts > 0 && (
                  <div className="retry-game-bts">
                    <Button
                      upper
                      gradient={{ left: "#6CB967", right: "#96F290" }}
                      hoverGradient={{ left: "#96F290", right: "#96F290" }}
                      onClick={retry}
                    >
                      Riprova subito
                      <ChevronRight size={30} />
                    </Button>
                  </div>
                )}
                <img
                  className={`background-pasqua ${
                    spring || natale || carnevale ? "" : "bg_lose"
                  } `}
                  src={
                    carnevale
                      ? process.env.PUBLIC_URL + "/images/carnevaleLoose.png"
                      : sempre
                      ? process.env.PUBLIC_URL + "/images/sempre-lose.png"
                      : spring
                      ? process.env.PUBLIC_URL + "/images/springLoose.png"
                      : natale
                      ? process.env.PUBLIC_URL + "/images/nataleLoose.png"
                      : new Date().getDay() ===
                        +process.env.REACT_APP_MONDAY_DAY
                      ? process.env.PUBLIC_URL +
                        "/images/bg_gameLunedi_loose.png"
                      : process.env.PUBLIC_URL +
                        "/images/bg_gameGiovedi_loose.png"
                  }
                  alt=""
                />
                {spring ? (
                  <img
                    class="loose_spring"
                    src={process.env.PUBLIC_URL + "/images/loose_spring.png"}
                    alt=""
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </GameWrapper>
        </ResultContainer>
      )}
    </>
  );
};

export default ResultGame;
